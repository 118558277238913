
export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Solvency',
    headLine: 'WHO WE ARE',
    description: 'Solvency Software Solutions is a full-services Information Technology Company providing IT consulting and staffing services to Fortune 1000 organizations.',
    description2: 'As a process oriented organization we recognize that people are the key drivers in the IT business and by being people centric in our approach, we create a unique differentiator in harnessing the intellectual energies of the human mind to run your business and build best of breed solutions for your enterprise.',
    description3: 'Solvency Software Solutions reputation for tracking down these hard-to-find skills soon attracted the attention of many companies in the field and we began supplying candidates throughout large Verticals. ',
    buttonLable: 'Get Started',
    imgStart: false,
    img: "https://i.ibb.co/GvQgyxS/business.png",
    alt: 'about image',
    dark: true,
    primary: true,
    darkText: true  
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Solvency',
    headLine: 'WHAT WE DO',
    description: 'Solvency Software Solutions is an IT organization that projects full range of IT services to its clients to maximize their ROI. It has an extensive background in a number of areas of IT consulting. Although our expertise crosses industry boundaries, most of our consulting work has been in the areas of Strategic Planning, Systems Integration, Project Management, Application Development, Software Development, Application Maintenances, IT Consulting, Product Development, Strategic Planning,.',
    description2: 'One of our key strengths is our commitment to building long-term relationships with our clients. We help them not just to fill their current vacancies, but to develop effective, flexible workforces that fit their business needs now and long into the future.',
    description3: 'Our clients also benefit from one of the most experienced resourcing teams in our industry. Each division is staffed by ‘discipline-focused’ consultants with a network of contacts and candidates in their chosen specialist area. These consultants have relevant industry experience which gives them a unique understanding of our clients and a valuable insight into their needs.',
    buttonLable: 'Get Started',
    imgStart: true,
    img: "https://acegif.com/wp-content/gifs/globe-9.gif",
    alt: 'globe gif',
    dark: true,
    primary: true,
    darkText: true
};

export const homeObjThree = {
    id: 'about',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Solvency Software Solutions is an E-verified company.',
    headLine: 'E-Verify',
    description: 'What is E-Verify?',
    description2: 'Instant Verification of Employment Eligibility,..E-Verifies most impressive features are its speed and accuracy. E-Verify is the only free, fast, online service of its kind that verifies employees data against millions of government records and provides results within as little as three to five seconds.',
    descLine1: <b>Used nationwide by more than 600,000 employers of all sizes.</b>,
    descLine2: <b>Used at more than 1.9 million hiring sites.</b>,
    descLine3: <b>Joined by about 1,400 new participating companies every week.</b>,
    descLine4: <b>One of the federal government’s highest-rated services for customer satisfaction.</b>,
    imgStart: false,
    img: "https://i.pinimg.com/originals/70/a5/52/70a552e8e955049c8587b2d7606cd6a6.gif",
    alt: 'E-verify gif',
    dark: true,
    primary: true,
    darkText: false
};