import React from 'react'
import {AddressContainer, 
    AddressH1, 
    AddressWrapper, 
    AddressP, 
    AddressIcon, 
    AddressH2, 
    AddressCard, 
    AddressPhone} from './AddressElements';
    
/* import Icon1 from '../../images/usa.jpg'
import Icon3 from '../../images/getintouch.jpg' */
import Icon2 from '../../images/IT-HUB.jpg'

const AddressSection = () => {
    return (
        <div id="contact">
            <AddressContainer >
                <AddressH1>Contact Us</AddressH1>
                <AddressWrapper>
                    {/* <AddressCard>
                        <AddressIcon src={Icon1} />
                        <AddressH2>Headquaters - USA</AddressH2>
                        <AddressP>205 Van Buren Street Suite 120 Herndon VA20170 US</AddressP>
                        <AddressEmail>Email: info@solvency.us</AddressEmail>
                        <AddressEmail>Sales: sales@solvency.us</AddressEmail>
                        <AddressEmail>Career: career@solvency.us</AddressEmail>
                    </AddressCard> */}
                    <AddressCard>
                        <AddressIcon src={Icon2} />
                        <AddressH2>Branch Office - India - Khammam</AddressH2>
                        <AddressP>IT HUB, 5th Floor, Block-B,  Indira Nagar Colony, Khammam, Telangana 507002 India</AddressP>
                        <AddressPhone>Ph: 9493316846</AddressPhone>
                    </AddressCard>
                    {/* <AddressCard>
                        <AddressIcon src={Icon3} />
                        <AddressH2>Branch Office - India - Hyderabad</AddressH2>
                        <AddressP>Street no 1, Kakathiya Nagar, Habsiguda, Hyderabad - 500007, Telangana India</AddressP>
                        <AddressPhone>Ph: 9849661686</AddressPhone>
                    </AddressCard> */}
                </AddressWrapper>
            </AddressContainer>
        </div>
    )
}

export default AddressSection
