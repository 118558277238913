import React from 'react';
import Icon1 from '../../images/web.svg'
import Icon2 from '../../images/app.svg'
import Icon3 from '../../images/software.svg'
import Icon4 from '../../images/cloud.svg'
import Icon5 from '../../images/InfraStructure.svg'
import Icon6 from '../../images/bpo.svg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesP, ServicesIcon, ServicesH2, ServicesCard/* , MarqueeContainer, MarqueeH2, MarqueeImg, Marquee  */} from './ServicesElements';


const Services = () => {
    return (
        <>
            <ServicesContainer id="services">
                <ServicesH1>Our Services</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                            <ServicesIcon src={Icon1} />
                            <ServicesH2>Web Development</ServicesH2>
                            <ServicesP>We develop static & fully Dynamic and Interactive UI Websites with user friendly interface by professional developers.</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon2} />
                            <ServicesH2>Application Development</ServicesH2>
                            <ServicesP>We made Application that provide unforgettable digital experiences for your target audience across all platforms.</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon3} />
                            <ServicesH2>Software Quality Testing </ServicesH2>
                            <ServicesP>We implement the best possible software testing methodologies and applications to the complete software life cycle</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon4} />
                            <ServicesH2>Cloud Storage Development</ServicesH2>
                            <ServicesP>Harness cloud infrastructures like AWS, Azure, Google, Docker to build custom applications and migrate legacy architectures.</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon5} />
                            <ServicesH2>InfraStructure Management</ServicesH2>
                            <ServicesP>We provides relevant expertise to ensure that your company's core IT infrastructure can adapt to the market's dynamic needs and deliver business value.</ServicesP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesIcon src={Icon6} />
                            <ServicesH2>Business Process Outsourcing</ServicesH2>
                            <ServicesP>Solvency provider of Business Process Outsourcing (BPO) focusing on the complex, voice and non-voice based segment of customer-care services.</ServicesP>
                        </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
           {/*  <MarqueeContainer>
                <MarqueeH2>CLIENTS</MarqueeH2>
                <Marquee behavior="scroll" direction="left" scrollamount="15">
                    <MarqueeImg src="https://i.ibb.co/s3hgf4t/Amazon.png" height="60px" width="130px" alt="amazon logo"/>
                    <MarqueeImg src="https://i.ibb.co/B2jRssw/biocon.png" height="50px" width="130px" alt="biocon logo"/>
                    <MarqueeImg src="https://i.ibb.co/nM3x5dg/cognizant.png" height="60px" width="130px" alt="cognizant logo"/>
                    <MarqueeImg src="https://i.ibb.co/ccLtX7H/ericsson1.jpg" height="60px" width="130px" alt="ericsson logo"/>
                    <MarqueeImg src="https://i.ibb.co/k9cmTdR/ibm.png" height="60px" width="130px" alt="ibm logo"/>
                    <MarqueeImg src="https://i.ibb.co/6FTKWW9/xerox.png" height="60px" width="130px" alt="xerox logo"/>
                </Marquee>
                <Marquee behavior="scroll" direction="right" scrollamount="15">
                    <MarqueeImg src="https://i.ibb.co/NZK4PrT/oracle.png" height="60px" width="130px" alt="oracle logo"/>
                    <MarqueeImg src="https://i.ibb.co/P9VpJNh/virtusa.png" height="60px" width="130px" alt="virtuse logo"/>
                    <MarqueeImg src="https://i.ibb.co/4t56Pw5/adp.jpg" height="60px" width="130px" alt="adp logo"/>
                    <MarqueeImg src="https://i.ibb.co/FnrWwk2/infosys.png" height="60px" width="130px" alt="infosys logo"/>
                    <MarqueeImg src="https://i.ibb.co/xYPcgtW/mphasis.jpg" height="60px" width="130px" alt="mphasis logo"/>
                    <MarqueeImg src="https://i.ibb.co/c6nGnjh/myntra.png" height="60px" width="130px" alt="myntra logo"/>
                </Marquee>
            </MarqueeContainer> */}
        </>
    )
}

export default Services
