import React, {useState} from 'react';
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data';
import Services from '../components/Services';
import FooterSection from '../components/Footer/index';
import AddressSection from '../components/ContactSection/AddressSection';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import ContactSection from '../components/ContactSection/index';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}>
                <ContactSection/>
            </Sidebar>
            <Navbar  toggle={toggle}>
                <ContactSection/>
            </Navbar>
            <HeroSection />
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <Services />
            <AddressSection />
            <ScrollToTop />
            <FooterSection />
        </>
    )
}

export default Home;
