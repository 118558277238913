import styled from 'styled-components';


export const ContactContainer = styled.div`
    min-hight: 80%;
    width:450px;

    @media screen and (max-width: 760px) {
        width: 300px;
    }

    @media screen and (max-width: 400px) {
        width:250px;
    }
`;


export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Form = styled.form`
    background: #010101;
    max-width: 750px;
    height: auto;
    width: 100%;
    display: grid;
    margin: 2px auto;
    padding: 40px 22px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9;);

    @media screen and (max-width: 400px) {
        padding: 15px 15px;
    }
`;


export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size:14px;
    color: #fff;
`;

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 25px;
    border: none;
    border-radius:4px;
`;

export const FormTextarea = styled.textarea`
    padding: 28px 28px;
    margin-bottom: 25px;
    border: none;
    border-radius:4px;
`;

export const FormButton = styled.button`
    /* background: #4ea39b;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 20px; */

    background-color: #1c87c9;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 20px;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
  }
`;