import Zoom from 'react-reveal/Zoom';
import React from 'react';
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, Img, ImgWrap } from './InfoElements';

const InfoSection = ({lightBg, imgStart, topLine, lightText, darkText, description, headLine, img, alt, id, description2, description3,descLine1, descLine2, descLine3, descLine4}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id} >
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle darkText={darkText}>{description}<br/>{description2}<br/>{description3}<br/>{descLine1}<br/>{descLine2}<br/>{descLine3}<br/>{descLine4}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Zoom>
                                    <Img src={img} alt={alt}/>
                                </Zoom>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
