import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import 'font-awesome/css/font-awesome.min.css';
import './footer.css'


const Footer = () => {
    return (
        <footer>
            <Box 
            px={{ xs: 3, sm: 10 }}
            py={{ xs: 5, sm: 10 }}
            bgcolor="#ADD8E6"
            color="#000">
                <Container maxWidth="lg">
                    <Grid container spacing={3} className="footer-grid">
                        <Grid item xs={12} sm={2}>
                            <Box borderBottom={1} pb={{xs: 2, sm:2}} fontSize={20}>Help</Box>
                            <Box pb={{xs: 2, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link to='contact' color="inherit" className="footer-link">
                                    Contact
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link href="/" color="inherit" className="footer-link">
                                    Support
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link href="/" color="inherit" className="footer-link">
                                    Privacy
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box borderBottom={1} pb={{xs: 2, sm:2}} fontSize={20}>Company</Box>
                            <Box pb={{xs: 2, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link to='discover' color="inherit" className="footer-link">
                                    Who We Are
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link href="/" color="inherit" className="footer-link">
                                    Careers
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link href="/" color="inherit" className="footer-link">
                                    Report Fraud
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link to='contact' color="inherit" className="footer-link">
                                    Contact
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box borderBottom={1} pb={{xs: 2, sm:2}} fontSize={20}>About</Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link to='discover' color="inherit" className="footer-link">
                                    Who We Are
                                </Link>
                            </Box>
                            <Box pb={{xs: 1, sm:1}} pt={{xs: 1, sm:2}}>
                                <Link to='discover' color="inherit" className="footer-link">
                                    What We Do
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box pt={{xs: 15, sm: 5}} pb={{xs: 15, sm: 4}} pl={{xs: 5, sm: 20}}>
                                <ul className="social-items">
                                    <li>
                                        <a className="social-icons" href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                                            <i className="fa fa-youtube" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </Box>
                            <Box className="footer-logo" pt={{xs: 10, sm: 7}} pl={{xs: 5, sm: 10}}>
                                <Link href="/"><img src="https://i.ibb.co/kQDcHP7/solvency-software-copy.png" width="150px" alt="logo"/></Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="copyrights-text" fontSize="20px" textAlign="center" pt={{xs: 5, sm: 10}} pb={{ xs: 0, sm: 0}}>
                        Copyrights &copy; 2021 Solvency Software Solutions. All rights reserved.
                    </Box>
                </Container>
            </Box>
        </footer>
    )
}

export default Footer