import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? 'lightblue' : '#010606')};


    @media screen and (max-width: 760px){
        padding: 100px 0;
    };
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 880px;
    width: 90%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -90px;
    padding: 0 20px;
    justify-content: center;
    @media screen and (min-width: 1400px) {
        width: 1370px;
    }
    @media screen and (min-width: 1600px) {
        width: 1570px;
        max-width: 1300px;
    }
    @media screen and (min-width: 1900px) {
        width: 1870px;
        max-width: 1300px;
    }
    @media screen and (max-width: 330px) {
        width: 230px;
        max-width: 270px;
    }
    @media screen and (max-width: 375px) {
        max-width: 320px;
    }
    @media only screen and (max-width: 500px) {
        width: 410px;
        height: 1100px
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 760px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 5px;
    padding: 0 20px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 5px;
    padding: 0 20px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 10px;

`;

export const TopLine = styled.p`
    color: blue;
    font-size: 18px;
    line-height: 16px;
    font-wight: Bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-family: arial;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 45px;
    line-height: 1.1;
    font-weigth: 600;
    color: ${({ lightText }) => (lightText) ? '#f7f8fa' : '#010606'};

    @media screen and (max-width: 480px){
        font-size: 32px;
    }

`;

export const Subtitle = styled.p`
    text-align: justify;
    max-width: 470px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 26px;
    color: ${({ darkText }) => (darkText) ? '#010606' : '#fff'};
    word-spacing: 0.1px;
    word-wrap: none;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width:100%;
    margin: 0 0 10px 0;
    padding-right: 0; 
`;
