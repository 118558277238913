import React, {Suspense, lazy } from 'react';
import './App.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './pages';
import NoMatch from './pages/404page/404page';
import PublicRoute from './components/Utils/PublicRoute';
import PrivateRoute from './components/Utils/PrivateRoute';
const Signin = lazy(() => import('./components/Signin/index'));
const SigninHome = lazy(() => import('./components/SigninPages/SigninHome'));
class App extends React.Component{

  render(){
    return (
      <Router>
        <Suspense fallback={<center>
          <img src="https://i.ibb.co/sH96Fm6/loader-gif.gif" alt="loader-gif" border="0"/>
        </center>}>
          <Switch>
            <Route path='/' component={Home} exact />
            <PublicRoute path='/signin' component={Signin} />
            <PrivateRoute path='/dashboard' component={SigninHome} />
            <Route path='*' component={NoMatch}/>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}




export default App;
