import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';


export const HeroContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 740px;
    position: relative;
    z-index: 1

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%), rgba(0,0,0,0.6) 100%), 
                    linear-gradient(180deg, rgba(0,0,0,0.2) 0%), transparent 100%);
        z-index: 2
    }

    @media screen and (max-width: 760px){
        height: 100vh;
    }

    @media screen and (max-width: 480px){
        height: 100vh;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right:0;
    bottom: 0;
    left: 0;
    width: 100%;
    heigth: 100%;
    overflow: hidden;
`;

/* export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34; 
`; */

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 100%;
    position: absolute;
    padding: 6px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeroH1 = styled.h1`
    color: blue;
    font-size: 38px;
    text-align: center;

    @media screen and (max-width: 760px){
        font-size: 38px;
    };

    @media screen and (max-width: 480px){
        font-size: 30px;
    };
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #000;
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    font-family: Consolas;


    @media screen and (max-width: 760px){
        font-size: 20px;
    };

    @media screen and (max-width: 480px){
        font-size: 14px;
    };
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    #fff;
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    #fff;
    margin-left: 8px;
    font-size: 20px;
`;