

const NoMatch =({history}) => {
    return(
        <div style={{margin:'auto',paddingTop:'20%',textAlign:'center'}}>
            <h1>404Page</h1>
            <h4>Redirecting to <span style={{color:'dodgerblue',cursor: 'pointer'}} onClick={() => history.push('/')}>Home Page</span></h4>
        </div>
    )
}

export default NoMatch;