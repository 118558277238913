import React, {useState, useEffect} from 'react';
import Particles from 'react-particles-js';
import {Button} from '../ButtonElement';
import Bounce from 'react-reveal/Zoom';


import {HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    useEffect(() => {
		document.title = 'Solvency Software Solutions';
	});



    return (
        <HeroContainer >
            <HeroBg>
            <Particles
                params={{
                    particles:{
                        number:{
                            value:150,
                            density:{
                                enable:true,
                                value_area:800
                            }
                        },
                        color:{
                            value:"#fff"
                        },
                        shape:{
                            type:"circle",
                            stroke:{
                                width:0,
                                color:"#ffffff"
                            },
                            polygon:{
                                nb_sides:5
                            },
                            image:{
                                src:"icons/react_1.svg",
                                width:100,
                                height:100
                            }
                        },
                        opacity:{
                            value:0.5,
                            random:false,
                            anim:{
                                enable:false,
                                speed:1,
                                opacity_min:0.1,
                                sync:false
                            }
                        },
                        size:{
                            value:2.5,
                            random:true,
                            anim:{
                                enable:false,
                                speed:40,
                                size_min:0.1,
                                sync:false
                            }
                        },
                        line_linked:{
                            enable:true,
                            distance:150,
                            color:"#0b9396",
                            opacity:0.55,
                            width:2
                        },
                        move:{
                            enable:true,
                            speed:2.5,
                            direction:"none",
                            random:true,
                            straight:false,
                            out_mode:"bounce",
                            bounce:false,
                            attract:{
                                enable:false,
                                rotateX:600,
                                rotateY:1200
                            }
                        }
                    },
                    interactivity:{
                        detect_on:"canvas",
                        events:{
                            onhover:{
                                enable:true,
                                mode:"repulse"
                            },
                            onclick:{
                                enable:true,
                                mode:"push"
                            },
                            resize:true},
                            modes:{
                                grab:{
                                    distance:400,
                                    line_linked:{
                                        opacity:1
                                    }
                                },
                                bubble:{
                                    distance:100,
                                    size:10,
                                    duration:2,
                                    opacity:8,
                                    speed:5
                                },
                                repulse:{
                                    distance:85,
                                    duration:0.4},
                                    push:{
                                    particles_nb:4
                                },
                                remove:{
                                    particles_nb:2
                                }
                            }
                        },
                        retina_detect:true
                    }
                }
                height="800px"
            />
            </HeroBg>
            <HeroContent>
                <Bounce left cascade>
                    <HeroH1>
                        Solvency Software Solutions                    
                    </HeroH1>
                </Bounce>
                <HeroP>
                Solvency Software Solutions is an established Software Development
                Outsourcing Company in Information Technology.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to='services' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                        Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
}

export default HeroSection
