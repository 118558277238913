import React,{Component} from 'react';
import {
    ContactContainer,
    FormContent,
    Form,
    FormLabel,
    FormInput,
    FormTextarea,
    FormButton
} from './ContactElements';


const instercontact = "/api/contacts";

class ContactSection extends Component{
    constructor(){
        super()
        this.state={
            name:'',
            phone:'',
            email:''
        }

    }

    handleChangeName = (event) => {
        this.setState({name:event.target.value})
    }

    handleChangeEmail =(event) => {
        this.setState({email:event.target.value})
    }

    handleChangeMessage =(event) => {
        this.setState({message:event.target.value})
    }

    handleSubmit = () => {
       fetch(instercontact,{
           method:'POST',
           headers:{
               'Accept':'application/json',
               'Content-Type':'application/json'
           },
           body:JSON.stringify(this.state)
       })
       alert("Thanks for Contacting Us");

    }
    

render(){
    console.log(this.props)
    return(
        <>
        <div id="contact">
            <ContactContainer>
                <FormContent>
                    <Form action="#" autoComplete="off">
                        <FormLabel htmlFor='for' >Name</FormLabel>
                        <FormInput type='text' required name="name" placeholder="Please Enter Your Name"
                            className="form-control" onChange={this.handleChangeName} />
                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' required name="email" placeholder="Please Enter Your Email" 
                            className="form-control" onChange={this.handleChangeEmail}/>
                        <FormLabel htmlFor='for'>Message</FormLabel>
                        <FormTextarea type='textarea' required name="message" placeholder="Message Us"
                            className="form-control" onChange={this.handleChangeMessage}/>
                        <FormButton type='submit' onClick={this.handleSubmit}>Send</FormButton>
                    </Form>
                </FormContent>
            </ContactContainer>
        </div>
        </>
    )
}
}

export default ContactSection;